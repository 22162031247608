const BankBranches = [
    "Abeka Lapaz",
    "Abrepo Branch",
    "Accra Mall",
    "Achimota",
    "Adum",
    "Aflao",
    "Amakomn",
    "Ashaiman",
    "Bantama",
    "Kasoa",
    "Weija",
    "Aplaku",
    "Maakro",
    "Tafo",
    "Ho",
    "Tepa",
    "Kokrobite"
]

export default BankBranches;