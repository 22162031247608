import { Link as RouterLink } from 'react-router-dom';


import { Box, Button, FormControl,  IconButton,  
    InputLabel, MenuItem, Paper, Select, TextField, 
    Typography, Link } from '@mui/material'
import { Stack } from '@mui/system';
import { useState } from 'react';

//icons
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LanguageIcon from '@mui/icons-material/Language';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PasswordIcon from '@mui/icons-material/Password';


import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EmailIcon from '@mui/icons-material/Email';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';


//account list type
import AccountList from '../../data/account';


const AccountSettings = () => {
  const [data, setData] = useState("Not Found");



  return (
    <Box marginTop={7}>
        <form>
            
            <Box sx={{display: "flex", justifyContent: "center", 
        marginTop: 10}}>

                <Paper elevation={24} rounded
                 sx={{display: "flex", flexDirection: "column",
            width: "500px", height: "750px", 
            borderRadius: 30
            }}>

                    
                <Box sx={{display: "flex", 
                flexDirection: "column", mt: 3,
            }}>
                    <Typography paddingTop={1}  variant='h4'
                    textAlign={"center"}
                    >Account Setting</Typography>
                    <Typography variant='body2' padding={1} 
                    textAlign={"center"} 
                    marginBottom={3}>
                        
                    </Typography>


</Box>
                






<Box>
    <Typography sx={{marginLeft: 0, mb: 3, backgroundColor: "#fffcfc", 
borderRadius: 3, height: 40, display: "flex", justifyContent: "center", flexDirection: "column", 
alignItems: "center"}}>
        Personalization
    </Typography>
</Box>       

                <Box sx={{ display: "flex",
                    flexDirection: "column", 
                    marginLeft: "auto", 
                    marginRight: "auto", 
                    cursor: "pointer" }}>




                <Stack direction={"row"} spacing={14}>

                <Box> 
                    <Button LinkComponent={RouterLink} to="/userprofile" >
                        <IconButton >
                        <PersonIcon  /> 
                        </IconButton>
                    </Button>
                </Box>

                <Box>
                    <Button LinkComponent={RouterLink} to="/userprofile">
                    <Typography> Profile </Typography>
                    </Button>
                </Box>

                <Box>
                    <Button LinkComponent={RouterLink} to="/userprofile">
                    <IconButton>
                    <ArrowForwardIosIcon  />
                    </IconButton>
                    </Button>
                </Box>


                </Stack>



                



    </Box>






<Box sx={{display: "flex", justifyContent: "center", 
m:1, minWidth: 120, cursor: "pointer" }}>

<Stack direction={"row"} spacing={13}>

<Box> 
    <Button LinkComponent={RouterLink} to="/userlanguage">
            <IconButton>
            <LanguageIcon />
            </IconButton>
    </Button>
</Box>

<Box>
    <Button  LinkComponent={RouterLink} to="/userlanguage">
    <Typography>Language </Typography>
    </Button>
</Box>

<Box>
    <Button  LinkComponent={RouterLink} to="/userlanguage">
        <IconButton>
        <ArrowForwardIosIcon  />
        </IconButton>
    </Button>
</Box>


</Stack>
        
</Box>












<Box sx={{display: "flex", justifyContent: "center", 
cursor: "pointer" }}>

<Stack direction={"row"} spacing={13}>

<Box> 
    <Button  LinkComponent={RouterLink} to="/userbiometric">
        <IconButton>
        <FingerprintIcon /> 
        </IconButton>
    </Button>
</Box>

<Box>
    <Button LinkComponent={RouterLink} to="/userbiometric">
    <Typography> Biometric </Typography>
    </Button>
</Box>

<Box>
    <Button LinkComponent={RouterLink} to="/userbiometric">
        <IconButton>
        <ArrowForwardIosIcon  />
        </IconButton>
    </Button>
</Box>


</Stack>
        
</Box>





<Box sx={{display: "flex", justifyContent: "center", 
cursor: "pointer" }}>

<Stack direction={"row"} spacing={7}>

<Box> 
    <Button LinkComponent={RouterLink} to="/userpassreset">
        <IconButton>
        <PasswordIcon /> 
        </IconButton>
    </Button>
</Box>

<Box>
    <Button LinkComponent={RouterLink} to="/userpassreset">
    <Typography> Reset Password/Pin </Typography>
    </Button>
</Box>

<Box>
    <Button LinkComponent={RouterLink} to="/userpassreset">
        <IconButton>
        <ArrowForwardIosIcon  />
        </IconButton>
    </Button>
</Box>


</Stack>
        
</Box>


{/* Another Session ************************************************************* */}



<Box>
    <Typography sx={{marginLeft: 0, mb: 3, backgroundColor: "#fffcfc", 
borderRadius: 3, height: 40,  display: "flex", justifyContent: "center", flexDirection: "column", 
alignItems: "center"}}>
        Others
    </Typography>
</Box>       

    <Box sx={{ display: "flex",
                    flexDirection: "column", 
                    marginLeft: "auto", 
                    marginRight: "auto", cursor: "pointer"}}>




                <Stack direction={"row"} spacing={10}>

                <Box> 
                    <Button LinkComponent={RouterLink} to="/usermarket"> 
                        <IconButton>
                        <LocalGroceryStoreIcon  /> 
                        </IconButton>
                    </Button>
                </Box>


                <Box>
                    <Button LinkComponent={RouterLink} to="/usermarket">
                    <Typography> Marketplace </Typography>
                    </Button>
                </Box>

                <Box>
                    <Button LinkComponent={RouterLink} to="/usermarket">
                        <IconButton>
                        <ArrowForwardIosIcon  />
                        </IconButton>
                    </Button>
                </Box>


                </Stack>



                



    </Box>






<Box sx={{display: "flex", justifyContent: "center", 
m:1, minWidth: 120,  cursor: "pointer"}}>

<Stack direction={"row"} spacing={14}>

<Box> 
    <Button LinkComponent={RouterLink} to="/userforex">
        <IconButton>
        <CurrencyExchangeIcon />
        </IconButton>
    </Button>
</Box>


<Box>
    <Button LinkComponent={RouterLink} to="/userforex">
    <Typography> Forex </Typography>
    </Button>
</Box>

<Box>
    <Button LinkComponent={RouterLink} to="/userforex">
        <IconButton>
        <ArrowForwardIosIcon  />
        </IconButton>
    </Button>
</Box>

</Stack>
        
</Box>












<Box sx={{display: "flex", justifyContent: "center", 
cursor: "pointer" }}>

<Stack direction={"row"} spacing={8}>

<Box> 
    <Button LinkComponent={RouterLink} to="/useremail">
        <IconButton>
        <EmailIcon /> 
        </IconButton>
    </Button>
</Box>

<Box>
    <Button  LinkComponent={RouterLink} to="/useremail">
    <Typography> Email Indemnity </Typography>
    </Button>
</Box>

<Box>
    <Button  LinkComponent={RouterLink} to="/useremail">
        <IconButton>
        <ArrowForwardIosIcon  />
        </IconButton>
    </Button>
</Box>


</Stack>
        
</Box>





<Box sx={{display: "flex", justifyContent: "center", 
cursor: "pointer" }}>

<Stack direction={"row"} spacing={13}>

<Box> 
    <Button  LinkComponent={RouterLink} to="/userreport">
        <IconButton>
        <AssessmentOutlinedIcon /> 
        </IconButton>
    </Button>

</Box>


<Box>
    <Button LinkComponent={RouterLink} to="/userreport">
    <Typography> Report </Typography>
    </Button>
</Box>

<Box>
    <Button LinkComponent={RouterLink} to="/userreport">
        <IconButton>
        <ArrowForwardIosIcon  />
        </IconButton>
    </Button>
</Box>


</Stack>
        
</Box>





































{/* Another Category *************************************************************** */}
   

</Paper>

            </Box>

            </form>
    </Box>
  )
}

export default AccountSettings
