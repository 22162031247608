import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system';




//account list type
import AccountList from '../../data/account';


const BankStatement = () => {
  return (
    <Box marginTop={7}>
        <form>
            
            <Box sx={{display: "flex", justifyContent: "center", 
        marginTop: 10}}>

                <Paper elevation={24} rounded
                 sx={{display: "flex", flexDirection: "column",
            width: "600px", height: "500px", 
            borderRadius: 30
            }}>

                    
                <Box sx={{display: "flex", 
                flexDirection: "column"
            }}>
                    <Typography paddingTop={1}  variant='h4'
                    textAlign={"center"}
                    >Bank Statement</Typography>
                    <Typography variant='body2' padding={1} 
                    textAlign={"center"} 
                    marginBottom={3}>
                        Do You want to know the transactions on Your account?
                    </Typography>


                </Box>
                

                <Box sx={{ display: "flex",
                    justifyContent: "center"}}>
                    
                    <FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, 
    [`& fieldset`]:{
        borderRadius:30
      }   }}>
<InputLabel id="demo-simple-select-helper-label">Select Account</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Select Account"
// onChange={handleCountryChange}
>
  {
      AccountList.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>

</FormControl>

    </Box>

<Box sx={{display: "flex", justifyContent: "center" }}>

    <TextField  type={"date"} 
    outlined
    defaultValue="Start Date"
    sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, 
    
    [`& fieldset`]:{
        borderRadius:30
      },    }}
    />
        
</Box>


<Box sx={{display: "flex", justifyContent: "center" }}>

    <TextField  type={"date"} 
    outlined
    defaultValue="End Date"
    sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, 
    
    [`& fieldset`]:{
        borderRadius:30
      },    }}
    />
        
</Box>


<Stack direction={"row"} sx={{marginTop: 3, display: "flex",
justifyContent: "center"
            
            }}>
                <Button variant='contained' 
                color='warning'
                sx={{width: "25px",  margin: 2}}
                 >
                Reset
                 </Button>


                 <Button variant='contained' 
                color='info'
                sx={{width: "25px", margin: 2}}
                 >
                Submit
                 </Button>
            </Stack>

   

</Paper>

            </Box>

            </form>
    </Box>
  )
}

export default BankStatement
