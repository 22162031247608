import { Avatar, Box, Button, IconButton, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const MyPayees = () => {
  return (
    <>
        <Box sx={{marginTop: "60px", display: "flex",
    justifyContent: "center"}}>
            <Paper elevation={24} 
            sx={{width: "600px", height: "400px", 
            borderRadius: 5, }}>
                <Typography variant='h4' mt={2} mb={2}
                textAlign={"center"}>
                My Payees
                </Typography>



            <Box sx={{display: "flex", marginLeft: 2, 
        marginBottom: 2}}>     
                <Avatar sx={{backgroundColor: "orange",
            height: 50, width: 50}}>
                    S
                </Avatar>

                <Stack direction={"column"} marginLeft={1}>
                    <Typography>Steph</Typography>
                    <Typography variant='body2'>
                        Ghs3000.00
                    </Typography>
                </Stack>

                <Box sx={{marginLeft: "auto", 
            marginRight: "10px"}}>
                    <IconButton>
                        <ArrowForwardSharpIcon />
                    </IconButton>
                </Box>
            </Box>








            <Box sx={{display: "flex", marginLeft: 2, 
        marginBottom: 2}}>
               
               <Avatar sx={{backgroundColor: "orange",
           height: 50, width: 50}}>
                   K
               </Avatar>

               <Stack direction={"column"} marginLeft={1}>
                   <Typography>Kofi</Typography>
                   <Typography variant='body2'>
                       Ghs6500.00
                   </Typography>
               </Stack>

               <Box sx={{marginLeft: "auto", 
           marginRight: "10px"}}>
                   <IconButton>
                       <ArrowForwardSharpIcon />
                   </IconButton>
               </Box>
           </Box>



           <Box sx={{display: "flex", marginLeft: 2, 
        marginBottom: 2}}>
               
               <Avatar sx={{backgroundColor: "orange",
           height: 50, width: 50}}>
                   Y
               </Avatar>

               <Stack direction={"column"} marginLeft={1}>
                   <Typography>Yaw</Typography>
                   <Typography variant='body2'>
                       Ghs5000.00
                   </Typography>
               </Stack>

               <Box sx={{marginLeft: "auto", 
           marginRight: "10px"}}>
                   <IconButton>
                       <ArrowForwardSharpIcon />
                   </IconButton>
               </Box>
           </Box>

            

            <Box sx={{display: "flex", 
        justifyContent: "center"}}>

            <Button size='small' 
             sx={{backgroundColor: "#7dc2e0", 
             height: "50px", width: "50px", borderRadius: "25%"}}>
                <IconButton>
                    <AddRoundedIcon />
                </IconButton>
            </Button>
            </Box>


            </Paper>
        </Box>
    </>
  )
}

export default MyPayees
