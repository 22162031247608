import React, {useState} from 'react'
import { Box } from '@mui/system'
import { Button, TextField, Typography,
FormControl, FormLabel, FormControlLabel, 
Radio, RadioGroup, InputLabel, Select, 
FormHelperText, MenuItem, Avatar, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LoginIcon from '@mui/icons-material/Login';
import { deepOrange, deepPurple } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


//account list type


//Account source
import AccountSource from '../../data/accountSource';

//destionation transfer
import DestinationAccount from '../../data/destinationAccount';


//bank transfer
import BankToBankData from '../../data/bank-bank';





const defaultFormField = {
  name: "",
  email: "",
  password: ""
}

const BankToBank = () => {

  const navigate = useNavigate()
  const [input, setInput] = useState(defaultFormField)
  const [branch, setBranch] = useState("")


  const navigateToLogin = () => (
      navigate("/")
  )

  const handleChange = (e) =>  {
    setInput((prevState) => ({
     ...prevState,
     [e.target.name] : e.target.value
    }))
 }


 const handleBranchChange = () => (
  setBranch(branch)
 )

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(input)
  }


  return (
    <div>
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column"
            maxWidth={400} alignItems="center"
            justifyContent={"center"} marginLeft="30%"
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>


                <Typography variant='h4' padding={1} 
                textAlign="center">
                    Bank Transfer
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  Transfer from one bank to another bank
                </Typography>


    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" />
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
      
       
        
      </RadioGroup>
    </FormControl>


<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">Transfer Type</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Select the type of transfer"
// onChange={handleCountryChange}
>
  {
      BankToBankData.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>






<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">Select Source Account</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Select Cheque Book Type"
// onChange={handleCountryChange}
>
  {
      AccountSource.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>





<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">
  Select the destination account
</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
name='branch'

label="Select the destination account"

>
  {
      DestinationAccount.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>


          <Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
            borderRadius:30
          } }}>
                <TextField 
                  required
                  fullWidth
                  id="amount"
                  label="Amount"
                  name="amount"
                  autoComplete="amount"
                />
          </Box>



          <Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
            borderRadius:10
          } }}>
                <TextField 
                multiline
                rows={4}
                  required
                  fullWidth
                  id="amount"
                  label="Comment"
                  name="comment"
                  autoComplete="comment"
                />
          </Box>








                <Button variant='contained' 
                color='warning'
                sx={{marginTop: 3, borderRadius: 5}}
                endIcon={<SensorOccupiedIcon />}
                 >
                Submit
                 </Button>

                <Button 
                sx={{marginTop: 3, borderRadius: 5}}
                onClick={navigateToLogin}
                type="submit"
                endIcon={<LoginIcon />}
                 >
                    Go Back
                 </Button>
                
                 

            </Box>
        </form>
    </div>
  )
}

export default BankToBank