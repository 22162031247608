import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

const PasswordGen = () => {
  return (
    <Box>
      <Typography variant='h1'>
        Hello World
      </Typography>
    </Box>
  )
}

export default PasswordGen
