//add account using box or account creation component
import { Button, IconButton, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import AddIcon from '@mui/icons-material/Add';

const AddAccount = () => {
  return (
    <div>
      <form>
      <Box display="flex" flexDirection="column"
            maxWidth={600} alignItems="center"
            justifyContent={"center"} marginLeft="auto" marginRight={"auto"}
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>
              
              <Box>
              <Typography variant='h4' padding={1} 
                textAlign="center">
                    Add Account
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  Adding New Account
                </Typography>

              </Box>





        <Box sx={{display: "flex", justifyContent:"center", width: "300px"}}>
    
        
        <TextField sx={{ marginBottom: 2, 
        [`& fieldset`]:{
          borderRadius:30
        }}}
        type="text" required 
        label="Account Number" variant='outlined'
        />

        </Box>

        <Box sx={{mt: 5, mb: 3}}>
        
        <Button size="small"
        variant='contained' sx={{backgroundColor: "#77c4e0"}}>
        <IconButton>
            <AddIcon />
        </IconButton>
        
        </Button>
        </Box>










      </Box>

      



      </form>
    </div>
  )
}

export default AddAccount
