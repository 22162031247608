import { Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add';
import ContactPhoneSharpIcon from '@mui/icons-material/ContactPhoneSharp';


const CreatePayee = () => {
  return (
    <Box sx={{marginTop: "50px"}}>

<form>
      <Box display="flex" flexDirection="column"
            maxWidth={600} alignItems="center"
            justifyContent={"center"} marginLeft="auto" marginRight={"auto"}
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>
              
              <Box>
              <Typography variant='h4' padding={1} 
                textAlign="center">
                    Create Payee
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  Create New Payee
                </Typography>

              </Box>





        <Box sx={{display: "flex", justifyContent:"center",
        flexDirection: "column",
        width: "300px"}}>
    
        
        <TextField sx={{ marginBottom: 2, [`& fieldset`]:{
     borderRadius:30
   }}}
        type="text" required 
        label="Name" variant='outlined'
        
        />


   <Stack direction="row" spacing={2}>
        <TextField sx={{ marginBottom: 2, width: "250px", 
        [`& fieldset`]:{
            borderRadius:30
          }}}
        type="text" required 
        label="Phone" variant='outlined'
        />

        <Box>
        <IconButton size='large'
         sx={{backgroundColor: "#36e1f1", "&:hover": {
          backgroundColor: "#076c76"
         } }}>
          <ContactPhoneSharpIcon />
        </IconButton>
        </Box>
  </Stack>

        <TextField sx={{ marginBottom: 2, 
        [`& fieldset`]:{
            borderRadius:30
          }}}
        type="text" required 
        label="Account Number" variant='outlined'
       
        />

        </Box>



        <Box sx={{mt: 5, mb: 3}}>
        
        <Button size="small"
        variant='contained' sx={{backgroundColor: "#77c4e0"}}>
        <IconButton>
            <AddIcon />
        </IconButton>
        
        </Button>
        </Box>










      </Box>

      



      </form>
        
    </Box>
  )
}

export default CreatePayee
