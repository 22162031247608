import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, Slider, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system';
import { useState } from 'react';


//account list type
import AccountList from '../../data/account';


const LoanRequest = () => {
    const [value, setValue] = useState(50);
    const [interest, setInterest] = useState(5)
    const [month, setMonth] = useState(7)

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const interestHandleChange = (event, newValue) => {
    setInterest(newValue);
};


const monthHandleChange = (event, newValue) => {
    setMonth(newValue);
};


function valuetext(value) {
    return `${value}°C`;
  }
 






  return (
    <Box marginTop={7}>
        <form>
            
            <Box sx={{display: "flex", justifyContent: "center", 
        marginTop: 10}}>

                <Paper elevation={24} rounded
                 sx={{display: "flex", flexDirection: "column",
            width: "600px", height: "900px", 
            borderRadius: 20
            }}>

                    
                <Box sx={{display: "flex", 
                flexDirection: "column"
            }}>
                    <Typography paddingTop={1}  variant='h4'
                    textAlign={"center"} mt={2}
                    >Loan Calculator</Typography>
                    <Typography variant='body2' padding={1} 
                    textAlign={"center"} 
                    marginBottom={3}>
                        You are requesting for a loan
                    </Typography>


                </Box>
                



<Box sx={{ display: "flex", justifyContent: "center"}}>
                    
<CircularProgress 
variant="determinate" 
thickness={3.6}
size="10rem"
max={1000}
value={value} 


>
   
</CircularProgress>

{/* <Typography textAlign={"center"}>
    GHS {value}
</Typography> */}

</Box>




<Box sx={{backgroundColor: "#e9e3e3", 
borderRadius: 3, height: 150, marginTop: 3,}}>

<Typography variant='h4' textAlign={"center"} 
sx={{mt: 2}}>
        GHS 2000/month
    </Typography>

<Box sx={{display: "flex", justifyContent: "center",
alignItems: "center", 
m:1, width: 300,  marginLeft: "auto", 
marginRight: "auto",  }}>

    

    <Stack direction={"row"} spacing={2} 
    sx={{justifyContent: "column"}}>

    <Box sx={{ width: 150,
textAlign: "center", }}>
        <Typography >Principal Amount</Typography>
        <Typography>Ghs 7000.00</Typography>
    </Box>

    <Box sx={{ width: 150, 
textAlign: "center"}}>
        <Typography>Interest</Typography>
        <Typography>Ghs 1050.00</Typography>
    </Box>


    </Stack>
        
</Box>
</Box>




<Box sx={{display: "flex", flexDirection: "column", 
m:1, minWidth: 150, maxWidth: 300, marginLeft: "auto",
marginRight: "auto",
 }}>

    <Typography textAlign={"center"}
    >Loan Amount: 
    </Typography>

    <Typography textAlign={"center"}>
        GHS{value} 
    </Typography>

    <Slider color='info'
    sx={{justifyContent: "center", width: 150, mb: 5}}
    max={1000}
    min={50}
    marks
    getAriaValueText={valuetext}
    valueLabelDisplay="auto"
    step={50}
    value={value}
    onChange={handleChange}
      >
        <Typography variant='caption'>
            {value}
        </Typography>
      </Slider>
        
</Box>


<Box sx={{display: "flex", flexDirection: "column", 
m:1, minWidth: 150, maxWidth: 300, marginLeft: "auto",
marginRight: "auto",
 }}>

<Typography textAlign={"center"}
    >Interest Rate: {interest} %</Typography>

    <Slider color='info'
    sx={{justifyContent: "center", width: 150}}
    max={50}
    min={1}
    marks
    step={1}
    value={interest}
    onChange={interestHandleChange}
      />
        
</Box>


<Box sx={{display: "flex", flexDirection: "column", 
m:1, minWidth: 150, maxWidth: 300, marginLeft: "auto",
marginRight: "auto",
 }}>

<Typography textAlign={"center"} mb={2}>
    Repayment period: 
</Typography>

<Typography textAlign={"center"}>
    {month} (Month)
</Typography>

    <Slider color='info'
    sx={{justifyContent: "center", width: 150}}
    max={60}
    min={1}
    marks
    step={1}
    value={month}
    onChange={monthHandleChange}
      />
        
</Box>












<Box sx={{display: "flex", justifyContent: "center" }}>

<Button variant='contained' color='info'
sx={{width: 250, margin: 1, height: 70, borderRadius: 10}}>
                 
                Apply for Loan
</Button>
        
</Box>




   

</Paper>

            </Box>

            </form>
    </Box>
  )
}

export default LoanRequest
