import React, {useState} from 'react'
import { Box } from '@mui/system'
import { Button, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LoginIcon from '@mui/icons-material/Login';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const defaultFormField = {
  name: "",
  title: "",
  date: "",
  comment: "",
  hello: ""
}

const AppointmentPage = () => {

  const navigate = useNavigate()
  const [input, setInput] = useState(defaultFormField)



  const navigateToLogin = () => (
      navigate("/")
  )

  const handleChange = (e) =>  {
    setInput((prevState) => ({
     ...prevState,
     [e.target.name] : e.target.value
    }))
 }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(input)
  }


  return (
    <div>
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column"
            maxWidth={400} alignItems="center"
            justifyContent={"center"} margin="auto"
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>


                <Typography variant='h3' paddingTop={3} 
                textAlign="center">
                    Appointment 
                </Typography>

                <Typography variant='body2'  
                textAlign="center">
                    Schedule An Appointment With one of our Agents
                </Typography>




                <TextField type={"text"}
                variant='outlined'
                color="secondary"

               
                sx={{width: 300, [`& fieldset`]:{
                  borderRadius:30
                }}}
                placeholder='Title'
                margin='normal' 
                name='title'
                onChange={handleChange}
                value={input.title}
                />


                <TextField type={"text"} 
                placeholder='Name'
                color="secondary"
                name='name'
                sx={{width: 300, [`& fieldset`]:{
                  borderRadius:30
                }}}
                onChange={handleChange}
                value={input.name}
                variant='outlined'
                margin='normal'
                 />


                
                <TextField type={"date"}
                variant='outlined'
                color='warning'
                sx={{width: 300, [`& fieldset`]:{
                  borderRadius:30
                }}}

                placeholder='Date'
                margin='normal' 
                name='date'
                size='normal'
                onChange={handleChange}
                value={input.date}
                />




                <TextField type={"time"}
                 size='normal'
                variant='outlined'
                color="warning"
                placeholder='Time'
                margin='normal' 
                name='time'
                sx={{width: 300, [`& fieldset`]:{
                  borderRadius:30
                }}}

                onChange={handleChange}
                value={input.time}
                />


               

                <TextField type={"text"} 

                sx={{width: 300, height: 200, [`& fieldset`]:{
                  borderRadius:10
                }}}
                multiline
                rows={4}
                defaultValue="Default Value"  
                color="success"
                autosize
                 variant='outlined' 
                placeholder='Comments' 
                margin='normal'
                name='comment'
                onChange={handleChange}
                value={input.comment}
                />

                <Button variant='contained' 
                color='warning'
                sx={{marginTop: 3, borderRadius: 5}}
                endIcon={<SensorOccupiedIcon />}
                 >
                Submit
                 </Button>

                <Button 
                sx={{marginTop: 3, borderRadius: 5}}
                onClick={navigateToLogin}
                type="submit"
                endIcon={<LoginIcon />}
                 >
                    Change to Login Page
                 </Button>
                
                 

            </Box>
        </form>
    </div>
  )
}

export default AppointmentPage