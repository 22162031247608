import React, {useState} from 'react'
import { Box } from '@mui/system'
import { Button, TextField, Typography,
FormControl, FormLabel, FormControlLabel, 
Radio, RadioGroup, InputLabel, Select, 
FormHelperText, MenuItem, Avatar, IconButton, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LoginIcon from '@mui/icons-material/Login';
import { deepOrange, deepPurple } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


//account list type
import AccountList from '../../data/account';

//Account source
import AccountSource from '../../data/accountSource';

//destionation transfer
import DestinationAccount from '../../data/destinationAccount';



//momo transfer
import MomoNetworkList from '../../data/momo-network';


//momo network 
import MomoTransferTypeList from '../../data/momo-transfer-type';
import { PhotoCamera } from '@mui/icons-material';



const defaultFormField = {
  name: "",
  email: "",
  password: ""
}

const ConfirmCheque = () => {

  const navigate = useNavigate()
  const [input, setInput] = useState(defaultFormField)
  const [branch, setBranch] = useState("")


  const navigateToLogin = () => (
      navigate("/")
  )

  const handleChange = (e) =>  {
    setInput((prevState) => ({
     ...prevState,
     [e.target.name] : e.target.value
    }))
 }


 const handleBranchChange = () => (
  setBranch(branch)
 )

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(input)
  }


  return (
    <div>
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column"
            maxWidth={400} alignItems="center"
            justifyContent={"center"} marginLeft="30%"
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>


                <Typography variant='h4' padding={1} 
                textAlign="center">
                    Confirm Cheque
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  You are confirming a cheque
                </Typography>


    


<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">Select Account</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Select account"
// onChange={handleCountryChange}
>
  {
      AccountList.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>








<Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300, 
          [`& fieldset`]:{
            borderRadius:30
          } }}>
                <TextField type={"tel"}
                  required
                  fullWidth
                  id="cheque_number"
                  label="Cheque Number"
                  name="check_number"
                  autoComplete="cheque_number"
                 
                  
                />
</Box>






<Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300,
          [`& fieldset`]:{
            borderRadius:30
          } }}>
                <TextField type={"text"}
                  required
                  fullWidth
                  id="issued_to"
                  label="issued To"
                  name="issue_to"
                  autoComplete="issued_to"
                  
                  
                />
        </Box>




          <Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300, 
          [`& fieldset`]:{
            borderRadius:30
          } }}>
                <TextField type={"date"}
                  required
                  fullWidth
                  id="issued_date"
                  
                  name="issued_date"
                  autoComplete="issued_date"
                />
          </Box>


          <Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300, 
          [`& fieldset`]:{
            borderRadius:30
          } }}>
                <TextField 
                  required
                  fullWidth
                  id="amount"
                  label="Amount"
                  name="amount"
                  autoComplete="amount"
                  helperText="Enter the Amount"
                />

          </Box>




          <Stack direction="row" alignItems="center" spacing={2}>
      <Button variant="contained" component="label" 
      sx={{width: 26}}>
        Gallery
        <input hidden accept="image/*" multiple type="file" />
      </Button>
      <IconButton color="primary" aria-label="upload picture" component="label">
        <input hidden accept="image/*" type="file" />
        <PhotoCamera />
      </IconButton>
    </Stack>




            <Box sx={{marginTop: 3, borderRadius: 50,
            display: "flex", justifyContent: "space-between"
            }}>
                <Button variant='contained' 
                color='error'
                sx={{width: "25px",  margin: 2}}
                 >
                Reset
                 </Button>


                 <Button variant='contained' 
                color='info'
                sx={{width: "25px", margin: 2}}
                 >
                Submit
                 </Button>
            </Box>

                <Button 
                sx={{marginTop: 3, borderRadius: 2}}
                onClick={navigateToLogin}
                type="submit"
                endIcon={<LoginIcon />}
                 >
                    Go Back
                 </Button>
                
                 

            </Box>
        </form>
    </div>
  )
}

export default ConfirmCheque