import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { Link as RouterLink, Outlet } from 'react-router-dom'
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const Navigation = () => {
  return (
    <>
    <AppBar sx={{
     background: "#0aaae9", marginBottom: "25px", 
     color: "white"}}>
        <Toolbar>
            <Typography >
                <Link component={RouterLink} to="/">
                    <Typography sx={{color: "whitesmoke"}}>
                    LOGO
                    </Typography>
                    
                </Link>
            </Typography>



        {/* <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/dashboard">
                    Dashboard
                </Link>
            </Typography>

        </Box> */}


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/appointment">
                    <Typography sx={{color: "whitesmoke"}}>
                    Appointment
                    </Typography>
                    
                </Link>
            </Typography>

        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/customerrequest">
                    <Typography sx={{color: "whitesmoke"}}>
                    Customer
                    </Typography>
                </Link>
            </Typography>

        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/travelrequest">
                        <Typography sx={{color: "whitesmoke"}}>
                        Travel Request
                        </Typography>
                </Link>
            </Typography>

        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/banktobank">
                    <Typography sx={{color: "whitesmoke"}}>
                    Bank To Bank
                    </Typography>
                </Link>
            </Typography>

        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/momotransfer">
                    <Typography sx={{color: "whitesmoke"}}>
                    Momo Transfer
                    </Typography>
                </Link>
            </Typography>

        </Box>



        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/merchantpayment">
                    <Typography sx={{color: "whitesmoke"}}>
                    Merchant
                    </Typography>
                </Link>
            </Typography>

        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/cardlimit">
                   <Typography sx={{color: "whitesmoke"}}>
                   CardLimit
                   </Typography>
                </Link>
            </Typography>

        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/blockcheque">
                   <Typography sx={{color: "whitesmoke"}}>
                   Block Cheque
                   </Typography>
                </Link>
            </Typography>

        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/confirmcheque">
                    <Typography sx={{color: "whitesmoke"}}>
                    Confirm Cheque
                    </Typography>
                </Link>
            </Typography>

        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/accountcreation">
                   <Typography sx={{color: "whitesmoke"}}>
                   Account Creation
                   </Typography>
                </Link>
            </Typography>

        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/purchasecredit">
                    <Typography sx={{color: "whitesmoke"}}>
                    Purchase
                    </Typography>
                </Link>
            </Typography>

        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/blockcard">
                    <Typography sx={{color: "whitesmoke"}}>
                    Block Card
                    </Typography>
                </Link>
            </Typography>

        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/cardupgrade">
                    <Typography sx={{color: "whitesmoke"}}>
                    Card Upgrade
                    </Typography>
                </Link>
            </Typography>
        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/addcard">
                   <Typography sx={{color: "whitesmoke"}}>
                   Add Card
                   </Typography>
                </Link>
            </Typography>
        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/addaccount">
                    <Typography sx={{color: "whitesmoke"}}>
                    Add Account
                    </Typography>
                </Link>
            </Typography>
        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/mypayees">
                    <Typography sx={{color: "whitesmoke"}}>
                    My Payees
                    </Typography>
                </Link>
            </Typography>
        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/createpayee">
                    <Typography sx={{color: "whitesmoke"}}>
                    Create Payees
                    </Typography>
                </Link>
            </Typography>
        </Box>


        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link component={RouterLink} to="/bankstatement">
                    <Typography sx={{color: "whitesmoke"}}>
                    Bank Statement
                    </Typography>
                    
                </Link>
            </Typography>
        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link sx={{color: "whitesmoke"}}
                 component={RouterLink} to="/qrpayment">
                    QR Payment
                </Link>
            </Typography>
        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link sx={{color: "whitesmoke"}} 
                 component={RouterLink} to="/loanrequest">
                    Loan Request
                </Link>
            </Typography>
        </Box>

        <Box flex="display" justifyContent="center" >
            <Typography>
                <Link  sx={{color: "whitesmoke"}} 
                 component={RouterLink} to="/accountsetting">
                    Account Setting
                </Link>
            </Typography>
        </Box>


        


        </Toolbar>
    </AppBar>

    

      <Outlet />
      <div style={{
        height: '60px'
      }}></div>
    </>
  )
}

export default Navigation
