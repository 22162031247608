const AtmLimitList = [
    "GHS3000",
    "GHS4000",
    "GHS5000",
    "GHS10000",
    "GHS12000",
    "GHS15000"
]


export default AtmLimitList;