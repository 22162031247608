import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const UserMarketPlace = () => {
  return (
    <Box sx={{marginTop: 10}}>
      <Typography>
        User MarketPlace Page
      </Typography>
    </Box>
  )
}

export default UserMarketPlace
