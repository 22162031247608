const MomoNetworkList = [
    "MTN Ghana",
    "Tigo Ghana",
    "Vodafone GH",
    "Glo Global",
    
]



export default MomoNetworkList