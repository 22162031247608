//add account using box or account creation component
import { Button, Card, CardActionArea, CardMedia, IconButton, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import AddIcon from '@mui/icons-material/Add';



//images
import visa from "../../assets/visa/visa.png"



const AddCard = () => {
  return (
    <div>
      <form>
      <Box display="flex" flexDirection="column"
            maxWidth={600} alignItems="center"
            justifyContent={"center"} marginLeft="auto" marginRight={"auto"}
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>
              
              <Box>
              <Typography variant='h4' padding={1} 
                textAlign="center">
                    Add Card
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  Adding New Credit Card
                </Typography>

              </Box>




    <Box sx={{display: "flex", justifyContent:"center", width: "300px"}}>
    
        
    <Card sx={{ width: 300, mb: 4, borderRadius: 10 }}>
      
      <CardMedia
        component="img"
        height="140"
        image={visa}
        alt="Visa Card"
      />
</Card>

    </Box>








        <Box sx={{display: "flex", justifyContent:"center", width: "300px"}}>
    
        
        <TextField sx={{ marginBottom: 2, width: 300,
        [`& fieldset`]:{
          borderRadius:30
        }}}
        type="text" required 
        label="Card Number" variant='outlined'
        
        />

        </Box>



        <Box sx={{
    width: 300
}}>

    
   <Stack spacing={2} direction={"row"} sx={{width: 300, mb: 3 }}>
    <TextField  sx={{width: 140,  [`& fieldset`]:{
          borderRadius:30
        }}}
    required 
    fullWidth
    name="card_expiry_date"
    label="expiry date"
    autoComplete="expiry_date"
    placeholder='Expiry Date '
    
    />



    <TextField  sx={{width: 140,  [`& fieldset`]:{
          borderRadius:30
        }}}
    required 
    fullWidth
    name="card_cvv"
    label="CVV"
    autoComplete="card_cvv"
    placeholder='CVV '
    />

</Stack> 
</Box>


<Box sx={{display: "flex", justifyContent:"center", width: "300px"}}>
    
        
        <TextField sx={{ marginBottom: 2, width: 300,
        [`& fieldset`]:{
          borderRadius:30
        }}}
        type="text" required 
        label="Card Holder's Name" variant='outlined'
        />

        </Box>









        <Box sx={{mt: 5, mb: 3, }}>
        
        <Button size="small" 
        variant='contained' sx={{backgroundColor: "#77c4e0", borderRadius: 10}}>
        <IconButton>
            <AddIcon />
        </IconButton>
        
        </Button>
        </Box>










      </Box>

      



      </form>
    </div>
  )
}

export default AddCard
