

const ChequeBookType = [
    "Bearer Cheque",
    "Order Cheque",
    "Crossed Cheque",
    "Open Cheque",
    "Post-Dated Cheque",
    "Stale Cheque",
    "Traveller's Cheque",
    "Self Cheque",
    "Banker's Cheque"
]


export default ChequeBookType