import React from 'react'
import { Routes, Route } from 'react-router-dom';



import LoginForm from './pages/login/login.page'
import LoginSide from './pages/login/loginSide.page'
import SignUpForm from './pages/sign-up/signup.page'
import Navigation from './Routes/Navigation/navigation.route'
import ForgotPass from './pages/forgot/password.page'
import PasswordGen from './pages/forgot/passGenerated.page'
import Billing from './layouts/billing';

import AppointmentPage from './pages/appointment.page';
import CustomerRequest from './pages/request/customer.page';
import TravelRequest from './pages/request/travel-request.page';
import BankToBank from './pages/transfer/bank-bank.page';
import MomoTransfer from './pages/transfer/momo-transfer';
import MerchantPayment from './pages/transfer/merchant';

import CardLimit from './pages/credit_card/card-limit';
import BlockCheque from './pages/cheque/block';
import ConfirmCheque from './pages/cheque/confirm';

//12/12/2022

import AccountCreation from './pages/account/account-creation';
import BuyCredit from './pages/payment/buy-credit';

//13/12/2022
import BlockCard from './pages/credit_card/block_card';
import CardUpgrade from './pages/credit_card/upgrade_card';

//14/12/2022
import AddAccount from './pages/account/add_account';

//15/12/2022
import MyPayees from './pages/payment/payee';
import CreatePayee from './pages/payment/create_payee';
import BankStatement from './pages/request/statement';

//16/12/2022
import QRPayment from './pages/payment/qr-payment';


//17/12/2022
import AddCard from './pages/credit_card/add_card';
import LoanRequest from './pages/request/loan';

//20/11/2022
import AccountSettings from './pages/account/settings';
import UserProfile from './pages/user/profile';
import UserLanguage from './pages/user/language';
import UserBiometric from './pages/user/biometric';
import UserResetPass from './pages/user/reset.password';
import UserMarketPlace from './pages/user/marketplace';
import UserForex from './pages/user/forex';
import UserReport from './pages/user/report';
import UserEmail from './pages/user/email-indemnity';



const App = () => {
  return (
    <div>
      <div>Hekki</div>
        <Routes>
          <Route path='/' element={<Navigation />}>
          {/* <Route index element={<LoginForm />} /> */}
          <Route path='signup' element={<SignUpForm />} />
          <Route index element={<LoginSide />} />
          <Route path='forgotpass' element={<ForgotPass />} />
          <Route path='/forgotpass/checkmail' element={<PasswordGen />}  />
          

          

          <Route path='dashboard' element={<Billing />} />
          
          <Route path="customerrequest" element={<CustomerRequest />} />

          <Route path='appointment' element={<AppointmentPage />} />

          <Route path='travelrequest' element={<TravelRequest  />} />
          <Route path='banktobank' element={<BankToBank />} />
          <Route path='momotransfer' element={<MomoTransfer />} />
          <Route path='merchantpayment' element={<MerchantPayment />} />
          <Route path="cardlimit" element={<CardLimit />} />
          <Route path='blockcheque' element={<BlockCheque />} />
          <Route path='confirmcheque' element={<ConfirmCheque />} />

          <Route path='accountcreation' element={<AccountCreation />} />
          <Route path='purchasecredit' element={<BuyCredit />} />
          <Route path='blockcard' element={<BlockCard />} />
          <Route path='cardupgrade' element={<CardUpgrade />} />

          <Route path='addaccount' element={<AddAccount />} />




          <Route path='addcard' element={<AddCard />} />





          <Route path='mypayees' element={<MyPayees />} />
          <Route path='createpayee' element={<CreatePayee />} />
          <Route path='bankstatement' element={<BankStatement />} />

          <Route path='qrpayment' element={<QRPayment />} />
          {/* <Route path='qrscan' element={<QRScan />} /> */}

          <Route path='loanrequest' element={<LoanRequest />} />
          <Route path='accountsetting' element={<AccountSettings />} />


          <Route path='userprofile' element={<UserProfile />} />
          <Route path='userlanguage' element={<UserLanguage />} />
          <Route path='userbiometric' element={<UserBiometric />} />
          <Route path='userpassreset' element={<UserResetPass />} />
          <Route path='usermarket' element={<UserMarketPlace />} />
          <Route path='userforex' element={<UserForex />} />
          <Route path='useremail' element={<UserEmail />} />
          <Route path='userreport' element={<UserReport />} />

          {/* <Route index element={<SignUpForm />} /> */}

          {/* <Route path="passGen" element={<PasswordGen />} /> */}
          
          </Route>

        </Routes>
    </div>
  )
}

export default App
