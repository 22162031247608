import React, {useState} from 'react'
import { Box } from '@mui/system'
import { Button, TextField, Typography,
FormControl, FormLabel, FormControlLabel, 
Radio, RadioGroup, InputLabel, Select, 
FormHelperText, MenuItem, Avatar, IconButton, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LoginIcon from '@mui/icons-material/Login';
import { deepOrange, deepPurple } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


//account list type
import AccountList from '../../data/account';

//Account source
import AccountSource from '../../data/accountSource';

//destionation transfer
import DestinationAccount from '../../data/destinationAccount';



//momo transfer
import MomoNetworkList from '../../data/momo-network';


//momo network 
import MomoTransferTypeList from '../../data/momo-transfer-type';
import { PhotoCamera } from '@mui/icons-material';


import CardBlockReason from "../../data/block-card-reason"
import BankBranches from '../../data/locationGh';

import UpgradeCard from '../../data/upgrade_card';

import AirtimeData from '../../data/airtime-data';
import BundleType from '../../data/bundleType';


const defaultFormField = {
  name: "",
  email: "",
  password: ""
}

const BuyCredit = () => {

  const navigate = useNavigate()
  const [input, setInput] = useState(defaultFormField)
  const [branch, setBranch] = useState("")


  const navigateToLogin = () => (
      navigate("/")
  )

  const handleChange = (e) =>  {
    setInput((prevState) => ({
     ...prevState,
     [e.target.name] : e.target.value
    }))
 }


 const handleBranchChange = () => (
  setBranch(branch)
 )

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(input)
  }


  return (
    <div>
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column"
            maxWidth={400} alignItems="center"
            justifyContent={"center"} marginLeft="30%"
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>


                <Typography variant='h4' padding={1} 
                textAlign="center">
                    Buy Credit/Data
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  Are you out of call credit/data?
                </Typography>


    


<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300,
    [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">Select Account</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Buy Airtime/Data"
// onChange={handleCountryChange}
>
  {
      AirtimeData.map((data) => (
          <MenuItem value={data}>{data}</MenuItem>
      ))
  }
</Select>
</FormControl>




<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300,
    [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">
    Select Network
</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Select Network"
// onChange={handleCountryChange}
>
  {
      MomoNetworkList.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>




<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300,
    [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">
    Select Bundle
</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Choose Bundle"
// onChange={handleCountryChange}
>
  {
      BundleType.map((data) => (
          <MenuItem value={data}>{data}</MenuItem>
      ))
  }
</Select>
</FormControl>




<FormControl sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
      borderRadius:30
    } }}>
<InputLabel id="demo-simple-select-helper-label">Select Account</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
// value={country}
label="Select Account"
// onChange={handleCountryChange}
>
  {
      AccountList.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>






<Box sx={{ display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          m: 1, minWidth: 120, width: 300,
          [`& fieldset`]:{
            borderRadius:30
          } }}>
                <TextField type={"tel"} 
                  required
                  fullWidth
                  id="amount"
                  outlined
                  label="Amount"
                  name="amount"
                  autoComplete="amount"
                />

          </Box>






            <Box sx={{marginTop: 3, borderRadius: 50,
            display: "flex", justifyContent: "space-between"
            }}>
                <Button variant='contained' 
                color='warning'
                sx={{width: "25px",  margin: 2}}
                 >
                Reset
                 </Button>


                 <Button variant='contained' 
                color='info'
                sx={{width: "25px", margin: 2}}
                 >
                Submit
                 </Button>
            </Box>

                <Button 
                sx={{marginTop: 3, borderRadius: 2}}
                onClick={navigateToLogin}
                type="submit"
                endIcon={<LoginIcon />}
                 >
                    Go Back
                 </Button>
                
                 

            </Box>
        </form>
    </div>
  )
}

export default BuyCredit