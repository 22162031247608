const CurrenciesList = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },

    {
      value: "GHS",
      label: "GHS"
    },
  ];


  export default CurrenciesList