import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PhoneTextField from "mui-phone-textfield";

//country list
import countryList from '../../data/countries';

//card list
import cardList from "../../data/cards" 
import { Paper } from '@mui/material';
import { Stack } from '@mui/system';



const theme = createTheme();





 const defaultFormField = {
    name: "",
    title: "",
    date: "",
    comment: "",
    arrivalDate: "",
    departureDate: "",
    card: "",
    phone: "",
  }




export default function TravelRequest() {

    const [input, setInput] = useState(defaultFormField)
    const [country, setCountry] = useState('');
    const [card, setCard] = useState("")


    //phone book
    const [isoValue, setIsoValue] = useState(""); // The input value.
    const [isocountry, setIsoCountry] = useState("GH"); // The selected country.
    const [phoneNumber, setPhoneNumber] = useState(); // The PhoneNumber instance.
  


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };



  

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleCardChange = (e) => {
    setCard(e.target.value)
  }


 
	const onChangeIso = ({ formattedValue, phoneNumber }) => {
		setIsoValue(formattedValue);
		setPhoneNumber(phoneNumber);
	};

	const onCountrySelect = ({ country, formattedValue, phoneNumber }) => {
		setIsoValue(formattedValue);
		setIsoCountry(country);
		setPhoneNumber(phoneNumber);
	};



  return (

    <ThemeProvider theme={theme}>


<Box sx={{   
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
backgroundImage: "url("+"https://images.unsplash.com/photo-1529074963764-98f45c47344b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1186&q=80" + ")",
backgroundPosition: 'center',
backgroundSize: 'cover',
backgroundRepeat: 'no-repeat', 
minHeight: "100vh", backgroundColor: "gray", opacity: "0.8"

}}>

<Paper elevation={24} sx={{width: 500}}>


  
<Box sx={{
  
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: "85vh"
  
}}>

<Avatar sx={{  background: "#D0650D" }}>
  <FlightTakeoffIcon />
</Avatar>





<Typography component="h1" variant="h5">
  Travel Request
</Typography>



<Box component="form" noValidate onSubmit={handleSubmit} 
sx={{mt: 3}}>


  <Grid container spacing={2}>


  <Grid item xs={12} sm={6}>

<FormControl sx={{ display: "flex",
flexDirection: "column",
justifyContent:"center",
 m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
  borderRadius:30
} }}>

<InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
value={country}
label="Select Country"
onChange={handleCountryChange}
>
  {
      countryList.map((country) => (
          <MenuItem value={country}>{country}</MenuItem>
      ))
  }
</Select>
</FormControl>


<TextField type={"date"} 
// name="name"
// value={input.arrivalDate}
// onChange={handleChange}
sx={{ display: "flex",
flexDirection: "column",
justifyContent:"center",
 m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
  borderRadius:30
} }}


/>

<TextField type={"date"} 
// name="name"
// value={input.arrivalDate}
// onChange={handleChange}
sx={{ display: "flex",
flexDirection: "column",
justifyContent:"center",
 m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
  borderRadius:30
} }}


/>



<Stack direction="row" spacing= {2} width={300} >

<FormControl sx={{ display: "flex",
flexDirection: "column",
justifyContent:"center",
  minWidth: 120, width: 150, [`& fieldset`]:{
    borderRadius:30
  } }}>
<InputLabel id="demo-simple-select-helper-label">
  Card
</InputLabel>
<Select
labelId="demo-simple-select-helper-label"
id="demo-simple-select-helper"
value={card}
label="Select Card"
onChange={handleCardChange}
>
  {
      cardList.map((card, index) => (
          <MenuItem key={index}
          value={card}>
            {card}
          </MenuItem>
      ))
  }
</Select>

</FormControl> 

<TextField  sx={{width: 180, [`& fieldset`]:{
      borderRadius:30
    }}}
    required 
    fullWidth
    name="card_number"
    autoComplete="pos_limit"
    label="Card Number"
    />

</Stack>


{/* <TextField type={"tel"} 

sx={{ display: "flex",
flexDirection: "column",
justifyContent:"center",
 m: 1, minWidth: 120, width: 300 }}
 helperText="E"
/> */}

<PhoneTextField
		label="Phone number"
		error={Boolean(setIsoValue && phoneNumber?.country !== country)}
		value={isoValue}
		country={isocountry}
		onCountrySelect={onCountrySelect}
		onChange={onChangeIso}

    sx={{ display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    m: 1, minWidth: 120, width: 300, [`& fieldset`]:{
      borderRadius:30
    } }}
	/>

</Grid>
    

  </Grid>

<Stack direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2} >
    <Button type='submit' variant='contained'>
      Submit
    </Button>

</Stack>

</Box>



</Box>

</Paper>

</Box>
    </ThemeProvider>
  );
}