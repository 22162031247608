const cardList = [
    "America Express",
    "Airplus",
    "Discover Card",
    "Maestro",
    "Mastercard",
    "Visa",
    "Visa Electron",
    "Visa Debit",
    "JCB",
    "Diners",
    "Cartebleue",
    "Dankort",
    "Visa Purchasing"
]

export default cardList