import React, {useState} from 'react'
import { Box } from '@mui/system'
import { Button, TextField, Typography,
FormControl, FormLabel, FormControlLabel, 
Radio, RadioGroup, InputLabel, Select, 
FormHelperText, MenuItem, Avatar, IconButton, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import LoginIcon from '@mui/icons-material/Login';
import { deepOrange, deepPurple } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import PhoneTextField from "mui-phone-textfield";


//account list type


//Account source
import AccountList from '../../data/account';

//Card Type
import cardList from '../../data/cards';

//ATM card limit
import AtmLimitList from '../../data/atm-limit';

//currencies
import CurrenciesList from '../../data/currencies';


//marital status data
import MaritalStatus from '../../data/maritalStatus';

import GenderList from '../../data/genderList';



const defaultFormField = {
  name: "",
  email: "",
  password: ""
}

const AccountCreation = () => {

  const navigate = useNavigate()
  const [input, setInput] = useState(defaultFormField)
  const [branch, setBranch] = useState("")
  const [currency, setCurrency] = useState("GHS")

  const [country, setCountry] = useState('');

   //phone book
   const [isoValue, setIsoValue] = useState(""); // The input value.
   const [isocountry, setIsoCountry] = useState("GH"); // The selected country.
   const [phoneNumber, setPhoneNumber] = useState(); // The PhoneNumber instance.


   //phone book

   const onChangeIso = ({ formattedValue, phoneNumber }) => {
    setIsoValue(formattedValue);
    setPhoneNumber(phoneNumber);
};

const onCountrySelect = ({ country, formattedValue, phoneNumber }) => {
    setIsoValue(formattedValue);
    setIsoCountry(country);
    setPhoneNumber(phoneNumber);
};








  const navigateToLogin = () => (
      navigate("/")
  )

  const handleChange = (e) =>  {
    setInput((prevState) => ({
     ...prevState,
     [e.target.name] : e.target.value
    }))
 }

 const currencyHandler = (e) => (
    setCurrency(e.target.value)
 )

 const handleBranchChange = () => (
  setBranch(branch)
 )

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(input)
  }


  return (
    <div>
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column"
            maxWidth={400} alignItems="center"
            justifyContent={"center"} marginLeft="30%"
            marginTop={10} padding={3} borderRadius={5}
            boxShadow={"5px 5px 10px #ccc"} 
            sx={{ ":hover": {
                boxShadow:"10px 10px 20px #ccc"
            }}}>


                <Typography variant='h4' padding={1} 
                textAlign="center">
                    Account Creation
                </Typography>

                <Typography variant='body2' paddingBottom={3}
                textAlign="center"
                >
                  Creating a new Account
                </Typography>






























<Box sx={{width: "300px"}}>
    <Stack direction="row" spacing={5} >
        
<TextField sx={{width: 130, marginBottom: 2, borderRadius: "50%",
[`& fieldset`]:{
    borderRadius:30
  }, }}
type="text" required 
label="Surname" variant='outlined'
/>

<TextField sx={{width: 130, marginBottom: 2, [`& fieldset`]:{
        borderRadius:30
      },  }}
type="text" required label="First name"
/>

    </Stack>

</Box>




<Box sx={{width: "300px"}}>
    <Stack direction="row" spacing={5} >
        
<TextField sx={{width: 130, marginBottom: 2, borderRadius: "50%", 
[`& fieldset`]:{
    borderRadius:30
  }, }}
type="date" required 
 
/>

<FormControl sx={{width: 130, [`& fieldset`]:{
        borderRadius:30
      }, }}>
<InputLabel id="demo-simple-select-label">Marital Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    
    label="Age"
    onChange={handleChange}
  >
   {
    MaritalStatus.map((status) => (
        <MenuItem value={status}>{status}</MenuItem>
    ))
   }
  </Select>
</FormControl>

    </Stack>

</Box>


<Box sx={{width: "300px"}}>

<Stack direction="row" spacing={5}>
    <TextField type={"text"} required
    label="Digital Address"
    sx={{width: 130, marginBottom: 2, 
        [`& fieldset`]:{
            borderRadius:30
          }, }} />

<PhoneTextField 
		label="Phone number"
		error={Boolean(setIsoValue && phoneNumber?.country !== country)}
		value={isoValue}
		country={isocountry}
		onCountrySelect={onCountrySelect}
		onChange={onChangeIso}

    sx={{ display: "flex", [`& fieldset`]:{
        borderRadius:30
      }, 
  
    marginBottom: 2, width: 130 }}
	/>

</Stack>

</Box>



<Box sx={{
    width: 300
}}>

    <FormControl sx={{width: 130, marginBottom: 2, 
    [`& fieldset`]:{
        borderRadius:30
      }, }}>
        <InputLabel>Gender</InputLabel>
        <Select 
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        // value={country}
        label="Sex">
            {
                GenderList.map((sex) => (
                    <MenuItem value={sex}> {sex} </MenuItem>
                ))
            }
        </Select>
    </FormControl>


    <TextField  sx={{width: 130, marginLeft: 5, marginBottom: 2, 
    [`& fieldset`]:{
        borderRadius:30
      }, }}
    required 
    fullWidth
    name="id_number"
    autoComplete="id_number"
    label="ID Number"
    />
</Box>



<Box sx={{width: 300}}>
<TextField sx={{width: 300, marginBottom: 2, [`& fieldset`]:{
        borderRadius:30
      }, }}
          id="email"
          type="email"
          label="Email"
          
        />

</Box>


<Box sx={{width: 300}}>

<Typography variant='body2' 
sx={{marginBottom: 2, }}>
            Emergency contact details:
        </Typography>

    <Stack direction="row" spacing={3} >

    <TextField type="text" required  
    label="Name" sx={{width: 150, [`& fieldset`]:{
        borderRadius:30
      }, }}  />

<PhoneTextField 
		label="Phone number"
		error={Boolean(setIsoValue && phoneNumber?.country !== country)}
		value={isoValue}
		country={isocountry}
		onCountrySelect={onCountrySelect}
		onChange={onChangeIso}

    sx={{ display: "flex", [`& fieldset`]:{
        borderRadius:30
      }, 
  
    marginBottom: 2, width: 130 }}
	/>



        
    </Stack>

</Box>






            <Box sx={{marginTop: 3, borderRadius: 50,
            display: "flex", justifyContent: "space-between"
            }}>
                <Button variant='contained' 
                color='error'
                sx={{width: "25px",  margin: 2}}
                 >
                Reset
                 </Button>


                 <Button variant='contained' 
                color='info'
                sx={{width: "25px", margin: 2}}
                 >
                Submit
                 </Button>
            </Box>

                <Button 
                sx={{marginTop: 3, borderRadius: 2}}
                onClick={navigateToLogin}
                type="submit"
                endIcon={<LoginIcon />}
                 >
                    Go Back
                 </Button>
                
                 

            </Box>
        </form>
    </div>
  )
}

export default AccountCreation